/*******************************************************************************
 * LOGER S.R.L.
 *
 * Proyecto Smaci San Nicolas
 * Fecha: 05/04/2019
 * Autor: Marco Abecasis
 *
 * RESUMEN:
 *  Servicio de acceso a la REST API de control de usuarios. En este servicio
 * se administran el logueo, deslogueo, renovacion de token, evaluacion de
 * permisos, etc. Junto con todas las funciones de administracion de usuarios,
 * como obtener la lista de los mismos, sus datos, actualizar, crear, y borrar.
 ******************************************************************************/

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { globalData, StatusText } from '../data/general-objects';
import { UserData, Permission, LoginInfo, AuthFeature } from '../data/auth-objects';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationServiceService {

  ///////////////////////////////////////////////////////////////////////////////////////
  // DEFINICIONES Y CONSTANTES
  ///////////////////////////////////////////////////////////////////////////////////////

  // Niveles de acceso
  public Levels = {
    VIEW: 1,
    CONTROL: 2,
    CONFIGURE: 4
  };

  // Nombre de las variables guardadas en el sessionStore
  private sessionVars = {
    user: 'smaci_user'
  };

  // URLs de los servicios que provee el REST server.
  private urls = {
    login: globalData.restBaseUrl + '/users/login/',
    logout: globalData.restBaseUrl + '/users/logout/',
    renewToken: globalData.restBaseUrl + '/users/new_token/',
    changePass: globalData.restBaseUrl + '/users/password_change/',
    adminChangePass: globalData.restBaseUrl + '/users/password_change_admin/',
    info: globalData.restBaseUrl + '/users/info/',
    infoAll: globalData.restBaseUrl + '/users/info_all/',
    update: globalData.restBaseUrl + '/users/update/',
    create: globalData.restBaseUrl + '/users/create/',
    delete: globalData.restBaseUrl + '/users/delete/',
    catFeatures: globalData.restBaseUrl + '/users/cat_features/'
  };

  ///////////////////////////////////////////////////////////////////////////////////////
  // FIELDS
  ///////////////////////////////////////////////////////////////////////////////////////

  private currentUserSubject: BehaviorSubject<LoginInfo>;
  public currentUser: Observable<LoginInfo>;
  public tokenTry = 0;

  ///////////////////////////////////////////////////////////////////////////////////////
  // CONSTRUCTOR
  ///////////////////////////////////////////////////////////////////////////////////////

  constructor(
    private http: HttpClient
  ) {
    // Cambiar a localStorage para mantener entre sessiones. Asi es una sesion, un logueo.
    this.currentUserSubject = new BehaviorSubject<LoginInfo>(JSON.parse(sessionStorage.getItem(this.sessionVars.user)));
    this.currentUser = this.currentUserSubject.asObservable();
    // this.fakeLogin();
  }

  ///////////////////////////////////////////////////////////////////////////////////////
  // OBTENCION Y/O VERIFICACION DE INFORMACION
  ///////////////////////////////////////////////////////////////////////////////////////

  /**
   * Retorna la informacion del usuario
   */
  public get currentUserValue(): LoginInfo {
    return this.currentUserSubject.value;
  }

  public get userName(): string {
    return this.currentUserSubject.value.userName;
  }

  /**
   * Determina si hay un usuario logueado
   */
  public get isUserLoggedIn(): boolean {
    return !(this.currentUserValue == null);
  }

  /**
   * Determina si el usuario tiene permiso para acceder a un determinado feature
   * con un nivel de acceso determinado
   * @param feature Caracteristica a la que se desea acceso
   * @param level Nivel con el que se desea acceder
   */
  public isUserAllowed(feature: string, level: number): boolean {

    if (!this.isUserLoggedIn) {
      return false;
    }

    const perm: Permission[] = this.currentUserValue.permissions;
    let value: boolean;

    perm.forEach(p => {
      if (p.feature === feature) {
        // tslint:disable-next-line:no-bitwise
        value =  (p.level & level) > 0;
      }
    });
    return value;
  }

  ///////////////////////////////////////////////////////////////////////////////////////
  // METODOS DE SOPORTE INTRENO
  ///////////////////////////////////////////////////////////////////////////////////////

  /**
   * Guarda informacion del usuario en el session storage
   * @param newUser Datos a guardar del usuario
   */
  private setUser(newUser: LoginInfo) {
    sessionStorage.setItem(this.sessionVars.user, JSON.stringify(newUser));
    this.currentUserSubject.next(newUser);
  }

  /**
   * Borra informacion del usuario del session storage
   */
  private clearUser() {
    sessionStorage.removeItem(this.sessionVars.user);
    this.currentUserSubject.next(null);
  }

  /**
   * Retorna un header estandar con tipo de body y token
   */
  public getStandardHeader() {
    return {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'token': this.currentUserValue.token
      }),
      params: {}
    };
  }

  /**
   * Retorna un header con tipo de contenido, pero sin token
   */
  public getNoTokenHeader() {
    return {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };
  }

  ///////////////////////////////////////////////////////////////////////////////////////
  // SERVICIOS
  ///////////////////////////////////////////////////////////////////////////////////////

  /**
   * Logueo en el REST server. De aca esperamos obtener los permisos y el token que nos va
   * a servir como credencial en la pagina.
   * @param username Nombre de usuario
   * @param password Contraseña del usuario
   */
  public login(username: string, password: string) {

    const userData = new UserData(0, username, password, '', '', [], '', '');

    return this.http.post<LoginInfo>(this.urls.login, userData, this.getNoTokenHeader()).pipe(
      tap( data => {
        // console.log(data);
        if (data.token !== '') {
          const loginInfo = new LoginInfo(data.userId, data.userName, data.token, []);
          data.permissions.forEach(p => loginInfo.permissions.push(new Permission(p.id, p.feature, p.level)));
          this.setUser(loginInfo);
        }
      })
    );
  }

  /**
   * Deslogueo voluntario del usuario
   */
  public logout() {
    if (!this.isUserLoggedIn) {
      return null;
    } else {
      const header = this.getStandardHeader();
      this.http.post(this.urls.logout, '', header).subscribe(
        data => { this.clearUser(); },
        error => { this.clearUser(); });
    }
  }

  /**
   * Renovacion de token, vencen cada cierto tiempo.
   */
  public renewToken() {
    if (this.isUserLoggedIn) {
      this.http.post<StatusText>(this.urls.renewToken, '', this.getStandardHeader()).subscribe(
          data => {
            if (data.msg === '') {
              this.clearUser();
              console.log('User not logged in. Logging out.');
            } else {
              this.currentUserValue.token = data.msg;
              this.setUser(this.currentUserValue);
              this.tokenTry = 0;
              // console.log('try to 0');
            }
          },
          error => {
            this.tokenTry++;
            // console.log('try++');
            if (this.tokenTry === 3) {
              console.log('Network error. Logging out.');
              this.logout();
            }
           });
    }
  }

  /**
   * Cambio de password
   * @param oldPassword Password vieja
   * @param newPassword Password nueva
   */
  public changePassword(oldPassword: string, newPassword: string): Observable<StatusText> {
    const userData = new UserData(this.currentUserValue.userId, '', newPassword, oldPassword, '', [], '', '');

    return this.http.post<StatusText>(this.urls.changePass, userData, this.getStandardHeader()).pipe(
      tap( data => { console.log(data.msg); } )
    );
  }

  /**
   * Cambio de password por administrador
   * @param userId Id del usuario
   * @param newPassword Password nueva
   */
  public changePasswordAdmin(userId: number, newPassword: string): Observable<StatusText> {
    const userData = new UserData(userId, '', newPassword, '', '', [], '', '');

    return this.http.post<StatusText>(this.urls.adminChangePass, userData, this.getStandardHeader()).pipe(
      tap( data => { console.log(data.msg); } )
    );
  }

  /**
   * Obtiene la informacion de un usuario
   * @param userId Id del usuario del que se desea la informacion
   */
  public getUserInfo(userId: number): Observable<UserData> {
    const userData = new UserData(userId, '', '', '', '', [], '', '');
    return this.http.post<UserData>(this.urls.info, '', this.getStandardHeader());
  }

  /**
   * Devuelve un listado con todos los usuarios
   */
  public getAllUserInfo(): Observable<UserData[]> {
    return this.http.get<UserData[]>(this.urls.infoAll, this.getStandardHeader()).pipe(
      map( data => data.map(
        p => new UserData(
          p.id,
          p.name,
          p.password,
          p.oldPassword,
          p.realName,
          p.permissions.map(
            q => new Permission(q.id, q.feature, q.level)
          ),
          p.email,
          p.phone
        )
        ))
      );
  }

  /**
   * Genera un update de datos de usuario. Esto lo usa el admin de usuario cuando los administra
   * desde la pagina.
   * @param userData Datos a actualizar del usuario
   */
  public updateUserData(userData: UserData): Observable<StatusText> {
    return this.http.post<StatusText>(this.urls.update, userData, this.getStandardHeader());
  }

  /**
   * Genera un usuario nuevo. Solo va a tener acceso un admin de usuarios.
   * @param userData Datos del nuevo usuario
   */
  public createUserData(userData: UserData): Observable<StatusText> {
    return this.http.post<StatusText>(this.urls.create, userData, this.getStandardHeader()).pipe();
  }

  /**
   * Da de baja un usuario. Solo usable por el admin. A los otros los va a rechazar el REST SERVER.
   */
  public deleteUserData(userId: number): Observable<StatusText> {
    const userData = new UserData(userId, '', '', '', '', [], '', '');
    return this.http.post<StatusText>(this.urls.delete, userData, this.getStandardHeader());
  }

  /**
   * Devuelve el catalogo de caracteristicas
   */
  public getFeaturesCatalog(): Observable<AuthFeature[]> {
    return this.http.get<AuthFeature[]>(this.urls.catFeatures, this.getStandardHeader()).pipe(
      map( data => data.map(
        p => new AuthFeature(
          p.featureId,
          p.feature
        )
      ))
    );
  }

  isUserNameOk(userName: string): boolean {
    return /^[a-zA-Z0-9ñÑ_]{4,20}$/g.test(userName);
  }

  isRealNameOk(realName: string): boolean {
    return /^[a-zA-ZñÑ]+[a-zA-Z ñÑ]{3,120}$/g.test(realName);
  }

  isEmailOk(email: string): boolean {
    return /^[A-Za-z0-9_ñÑ\.\-]+@[A-Za-z0-9_ñÑ\-]+\.[A-Za-z\.]+$/g.test(email);
  }

  isPhoneOk(telefono: string): boolean {
    return /^\+[0-9]{5,16}$/.test(telefono);
  }

  isPasswordOk(pass: string): boolean {
    return /^[A-Za-z0-9ñÑ _\-\#@.:!¡?¿]{6,16}$/.test(pass);
  }

}
