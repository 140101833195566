/*******************************************************************************
 * LOGER S.R.L.
 *
 * Proyecto Smaci San Nicolas
 * Fecha: 25/04/2019
 * Autor: Marco Abecasis
 *
 * RESUMEN:
 *  Componente contenedor principal. O sea, el menu.
 ******************************************************************************/
import { Component, OnInit, OnDestroy } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GeneralInfoService } from '../services/general-info.service';
import { AuthenticationServiceService } from 'src/app/services/authentication-service.service';
import { globalData } from '../data/general-objects';

///////////////////////////////////////////////////////////////////////////////
// ENUMERADORES INTERNOS
///////////////////////////////////////////////////////////////////////////////

export enum ExpandedItems {
  NONE,
  CLIMA,
  AGUA,
  ENERGIA,
  CLOACA,
  LUMINARIA,
  SEGURIDAD,
  WIFI,
  SUBE,
  IMPUESTOS,
  TRANSITO,
  SEGUIMIENTO,
  USUARIO,
  ADMINISTRAR,
  AUDITOR,
  STOCK_EQUIPOS,
  MAPA_CAPA,
  DESPACHOS,
  UNDEFINED
}

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit, OnDestroy {

  /////////////////////////////////////////////////////////////////////////////
  // FIELDS
  /////////////////////////////////////////////////////////////////////////////

  private interval;
  tituloPrincipal: string;
  expandedMenu: ExpandedItems = ExpandedItems.NONE;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );


  /////////////////////////////////////////////////////////////////////////////
  // CONSTRUCTOR Y CICLO DE VIDA
  /////////////////////////////////////////////////////////////////////////////

  constructor(
    private breakpointObserver: BreakpointObserver,
    private generalInfoService: GeneralInfoService,
    public authService: AuthenticationServiceService
    ) {}

    ngOnInit() {
      this.generalInfoService.tituloPrincipal$.subscribe(tituloPrincipal => this.tituloPrincipal = tituloPrincipal);
      this.StartTokenTimer();
      //set the current language
      let lang = this.generalInfoService.getActiveLanguage();
      this.generalInfoService.useLanguage(lang);
    }

    ngOnDestroy() {
      this.StopTokenTimer();
    }

  /////////////////////////////////////////////////////////////////////////////
  // METODOS DE CONTROL DE MENUES
  /////////////////////////////////////////////////////////////////////////////

    IsExpanded(menuExpand: ExpandedItems): boolean {
     // console.log('IsExpanded ', this.expandedMenu)
      return (menuExpand === this.expandedMenu);
    }

    ToggleExpand(menuExpand: ExpandedItems) {
      if (menuExpand === this.expandedMenu) {
        this.expandedMenu = ExpandedItems.NONE;
      } else {
        this.expandedMenu = menuExpand;
      }
    //  console.log('ToggleExpand ', this.expandedMenu)
    }

    ConvertToExpandItem(item: string): ExpandedItems {
      const option = item.toUpperCase();

      switch (option) {
        case 'NONE': return ExpandedItems.NONE;
        case 'CLIMA': return ExpandedItems.CLIMA;
        case 'AGUA': return ExpandedItems.AGUA;
        case 'ENERGIA': return ExpandedItems.ENERGIA;
        case 'CLOACA': return ExpandedItems.CLOACA;
        case 'LUMINARIA': return ExpandedItems.LUMINARIA;
        case 'SEGURIDAD': return ExpandedItems.SEGURIDAD;
        case 'WIFI': return ExpandedItems.WIFI;
        case 'SUBE': return ExpandedItems.SUBE;
        case 'IMPUESTOS': return ExpandedItems.IMPUESTOS;
        case 'TRANSITO': return ExpandedItems.TRANSITO;
        case 'SEGUIMIENTO': return ExpandedItems.SEGUIMIENTO;
        case 'USUARIO': return ExpandedItems.USUARIO;
        case 'ADMINISTRAR': return ExpandedItems.ADMINISTRAR;
        case 'STOCK_EQUIPOS': return ExpandedItems.STOCK_EQUIPOS;
        case 'AUDITOR': return ExpandedItems.AUDITOR;
        case 'MAPA-CAPA': return ExpandedItems.MAPA_CAPA;
      }

      return ExpandedItems.UNDEFINED;
    }

    Logout() {
      this.authService.logout();
      this.expandedMenu = ExpandedItems.NONE;
    }

    LoggedIn() {
      this.expandedMenu = ExpandedItems.NONE;
    }

    IsAllowedPozosExpanderClima(level: number): boolean {
      return this.IsExpanded(this.ConvertToExpandItem('CLIMA')) && this.authService.isUserAllowed('CLIMA', level);
    }

    IsAllowedPozosExpanderAgua(level: number): boolean {
      return this.IsExpanded(this.ConvertToExpandItem('AGUA')) && this.authService.isUserAllowed('AGUA', level);
    }

    IsAllowedPozosExpanderEnergia(level: number): boolean {
      return this.IsExpanded(this.ConvertToExpandItem('ENERGIA')) && this.authService.isUserAllowed('ENERGIA', level);
    }

    IsAllowedPozosExpanderCloaca(level: number): boolean {
      return this.IsExpanded(this.ConvertToExpandItem('CLOACA')) && this.authService.isUserAllowed('CLOACA', level);
    }

    IsAllowedPozosExpanderLuminaria(level: number): boolean {
      return this.IsExpanded(this.ConvertToExpandItem('LUMINARIA')) && this.authService.isUserAllowed('LUMINARIA', level);
    }

    IsAllowedExpanderCamaras(level: number): boolean {
      return this.IsExpanded(this.ConvertToExpandItem('SEGURIDAD')) && this.authService.isUserAllowed('CAMARAS', level);
    }

    IsAllowedClima(level: number): boolean {
      return this.authService.isUserAllowed('CLIMA', level);
    }

    IsAllowedAgua(level: number): boolean {
      return this.authService.isUserAllowed('AGUA', level);
    }

    IsAllowedEnergia(level: number): boolean {
      return this.authService.isUserAllowed('ENERGIA', level);
    }

    IsAllowedCloaca(level: number): boolean {
      return this.authService.isUserAllowed('CLOACA', level);
    }

    IsAllowedLuminaria(level: number): boolean {
      return this.authService.isUserAllowed('LUMINARIA', level);
    }

    IsAllowedSeguridad(): boolean {
      // console.log(this.authService.isUserAllowed('POZOS', level));
      return this.authService.isUserAllowed('CAMARAS', this.authService.Levels.VIEW);
    }

    ///////////////////////////////////////////////////////////////////////////
    // METODOS DE CONTROL DE TOKEN
    ///////////////////////////////////////////////////////////////////////////

    StartTokenTimer() {
      // console.log('timer iniciado');
      this.authService.renewToken();

      this.interval = setInterval(() => {
        if (this.authService.isUserLoggedIn) {
          this.authService.renewToken();
        }
        // console.log('new Token ' + this.authService.currentUserValue.token);
      }, globalData.tokenRenewInterval);
    }

    StopTokenTimer() {
      clearInterval(this.interval);
      // console.log('Timer Terminado');
    }
}
