import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule, MatButtonModule, MatSidenavModule, MatIconModule, MatListModule, MatCardModule,
  MatExpansionModule, MatTableModule, MatSortModule, MatMenuModule, MatFormFieldModule, MatInputModule,
  MatRadioModule, MatCheckboxModule, MatSelectModule, MatDatepickerModule } from '@angular/material';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { SearchBoxComponent } from './search-box/search-box.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChartsModule } from 'ng2-charts';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PasswordDialogComponent } from './password-dialog/password-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MessageBoxComponent } from './message-box/message-box.component';
import { MatSliderModule } from '@angular/material/slider';
import { TranslateModule } from '@ngx-translate/core';
import { ComplexGraphicComponent } from './complex-graphic/complex-graphic.component';

@NgModule({
  declarations: [
  SearchBoxComponent,
  PasswordDialogComponent,
  MessageBoxComponent,
  ComplexGraphicComponent
  ],
  imports: [
    CommonModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatCardModule,
    MatExpansionModule,
    MatTableModule,
    MatSortModule,
    MatMenuModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    FormsModule,
    ReactiveFormsModule,
    ChartsModule,
    MatTooltipModule,
    MatDialogModule,
    MatSnackBarModule,
    MatCheckboxModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatSliderModule,
    TranslateModule
  ],
  exports: [
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatCardModule,
    MatExpansionModule,
    MatTableModule,
    MatSortModule,
    MatMenuModule,
    MatFormFieldModule,
    SearchBoxComponent,
    MatInputModule,
    MatRadioModule,
    FormsModule,
    ReactiveFormsModule,
    ChartsModule,
    MatTooltipModule,
    MatDialogModule,
    PasswordDialogComponent,
    MatSnackBarModule,
    MatCheckboxModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MessageBoxComponent,
    MatDatepickerModule,
    MatMomentDateModule,
    MatSliderModule,
    TranslateModule,
    ComplexGraphicComponent
  ],
  entryComponents: [
    PasswordDialogComponent,
    MessageBoxComponent,
    ComplexGraphicComponent,
  ]
})
export class ShareModule { }
