// Datos globales
export const globalData = {
  restBaseUrl: 'https://gbe.urbibot.com:8090/api',
  //restBaseUrl: 'https://localhost:8085/api',
  tokenRenewInterval: 10 * 60 * 1000,
  mapLatitud: -33.3380397,
  mapLongitud: -60.2260557,
  mapZoom: 13
};

// Identifica un tipo de contactor
export enum ContactorType {
  NC = 0,
  NO = 1
}

export const ContactorConversionList = [
  { ref: 'NC', val: ContactorType.NC },
  { ref: 'NO', val: ContactorType.NO }
];

// Indica como se efectua el comando
export enum TipoComando {
  BLOQUEADO = 0,
  PULSO = 1,
  ESTADO = 2
}

export const DaysOfWeekData = [
  {ref:'Dom', val:'domingo'},
  {ref:'Lun', val:'lunes'},
  {ref:'Mar', val:'martes'},
  {ref:'Mier', val:'miercoles'},
  {ref:'Jue', val:'jueves'},
  {ref:'Vie', val:'viernes'},
  {ref:'Sab', val:'sabado'},
]

export const TipoComandoConversionList = [
  { ref: 'bloqueado', val: TipoComando.BLOQUEADO },
  { ref: 'estado', val: TipoComando.ESTADO },
  { ref: 'pulso', val: TipoComando.PULSO }
];

export const TipoComandoConversionListSmall = [
  { ref: 'blq', val: TipoComando.BLOQUEADO },
  { ref: 'est', val: TipoComando.ESTADO },
  { ref: 'pul', val: TipoComando.PULSO }
];

// Para el control de los charts
export class ChartInfo {
  constructor(
    public labels: string[],
    public data: number[],
    public type: string,
    public colors: string[]
  ) { }
}

// Estructura de usuario
export class User {
  id: number;
  username: string;
  // password: string;
  firstName: string;
  lastName: string;
  token: string;
  permissions: string[];
}

/**
 * Respuestas de codigo y texto.
 */
export class StatusText {
  public codigo: number;
  public msg: string;
}

export class ForeachUtilities {
  public static async asyncForEach(array, callback){
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }
}


