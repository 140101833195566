import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';

@Component({
  selector: 'app-message-box',
  templateUrl: './message-box.component.html',
  styleUrls: ['./message-box.component.css']
})
export class MessageBoxComponent implements OnInit {

  style: number;
  title: string;
  message: string;
  information: string;
  button: number;
  allow_outside_click: boolean;

  constructor(
    public dialogRef: MatDialogRef<MessageBoxComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // console.log(data);
    this.style = data.style || 0;
    this.title = data.title;
    this.message = data.message;
    this.information = data.information;
    this.button = data.button;
    this.dialogRef.disableClose = !data.allow_outside_click || false;

  }

  ngOnInit() {

  }

  onOk() {
    this.dialogRef.close({result: 'ok'});
  }
  onCancel() {
    this.dialogRef.close({result: 'cancel'});
  }
  onYes() {
    this.dialogRef.close({result: 'yes'});
  }
  onNo() {
    this.dialogRef.close({result: 'no'});
  }
  onAccept() {
    this.dialogRef.close({result: 'accept'});
  }
  onReject() {
    this.dialogRef.close({result: 'reject'});
}

}
