export const authFeatures = {
  pozos: 'POZOS',
  usuarios: 'USUARIOS',
  clima: 'CLIMA',
  agua: 'AGUA',
  energia: 'ENERGIA',
  cloaca: 'CLOACA',
  administrar: 'ADMINISTRAR',
  luminaria: 'LUMINARIA',
  camaras: 'CAMARAS',
  configurador: 'CONFIGURADOR',
  auditor: 'AUDITOR',
  transito: 'TRANSITO',
  sube: 'SUBE',
  stock:'STOCK_EQUIPOS'
};

export enum authLevels {
  VIEW = 1,
  CONTROL = 2,
  CONFIG = 4
}

// Estructura general de datos de usuario
export class UserData {
  constructor (
    public id: number,
    public name: string,
    public password: string,
    public oldPassword: string,
    public realName: string,
    public permissions: Permission[],
    public email: string,
    public phone: string
  ) { }
}

// Estructura de permisos
export class Permission {
  constructor (
    public id: number,
    public feature: string,
    public level: number
  ) { }

  public canUseFeature(level: number): boolean {
    // console.log('Feature: ' + this.feature + ' - Level Req: ' + level + ' - Result: ' + ((this.level & level) > 0));
    // tslint:disable-next-line:no-bitwise
    return (this.level & level) > 0;
  }

  public setFeature(level: number) {
    // tslint:disable-next-line:no-bitwise
    if ((this.level & level) === 0) {
      // tslint:disable-next-line:no-bitwise
      this.level |= level;
      // console.log('setset');
    }
  }

  public clearFeature(level: number) {
    // tslint:disable-next-line:no-bitwise
    if ((this.level & level) === 1) {
      // tslint:disable-next-line:no-bitwise
      this.level &= ~level;
      // console.log('clicli');
    }
  }
}

// Estructura de devolucion de token
export class LoginInfo {
  constructor (
    public userId: number,
    public userName: string,
    public token: string,
    public permissions: Permission[]
  ) { }
}

export class AuthFeature {
  constructor (
    public featureId: number,
    public feature: string
  ) { }
}
