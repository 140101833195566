import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import * as Highcharts from 'highcharts';
import { BehaviorSubject } from 'rxjs';
import HC_exporting from "highcharts/modules/exporting";
import HC_Data from "highcharts/modules/export-data";
HC_exporting(Highcharts);
HC_Data(Highcharts);

@Component({
  selector: 'app-complex-graphic',
  templateUrl: './complex-graphic.component.html',
  styleUrls: ['./complex-graphic.component.css']
})
export class ComplexGraphicComponent implements OnInit {

  Highcharts: typeof Highcharts = Highcharts;
  showComplexG = false;
  public complexGChart: any;
  id;
  @Input('data')
  series: BehaviorSubject<any>;

  constructor() { }

  ngOnInit() {
    this.id = Math.random();
    document.getElementById('complexGA').id = this.id.toString();
    this.series.subscribe(value => {
      if (value != undefined) {
        this.createComplexG(value['series'], value['yAxis'], value['xAxis']);
      }
    });
  }

  createComplexG(series, yAxis, xValues) {
    if (this.complexGChart != null) {
      this.complexGChart.destroy();
    }

    // @ts-ignore
    this.complexGChart = new Highcharts.chart(this.id.toString(), {
      chart: {
        zoomType: 'x'
      },
      title: {
        text: '',
        align: 'left'
      },
      xAxis: [{
        categories: xValues,
        crosshair: true
      }],
      yAxis: yAxis,
      tooltip: {
        shared: true
      },
      exporting: {
        enabled: true,
        showTable: false
      },
      credits: {
        enabled: false
      },
      series: series,
      plotOptions: {
        series: {
            marker: {
                enabled: false
            }
        }
      },
      responsive: {
        rules: [{
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            legend: {
              floating: false,
              layout: 'horizontal',
              align: 'center',
              verticalAlign: 'bottom',
              x: 0,
              y: 0
            },
            yAxis: [{
              labels: {
                align: 'right',
                x: 0,
                y: -6
              },
              showLastLabel: false
            }, {
              labels: {
                align: 'left',
                x: 0,
                y: -6
              },
              showLastLabel: false
            }, {
              visible: false
            }]
          }
        }]
      }
    });

  }

}
