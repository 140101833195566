import { Component, OnInit } from '@angular/core';
import { GeneralInfoService } from './services/general-info.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent{
  title = 'sn-app';
} 

