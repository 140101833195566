import { Injectable } from '@angular/core';
import { CoreModule } from './core.module';
import { Subject, Observable } from 'rxjs';
import { ContactorType, TipoComando, ContactorConversionList, TipoComandoConversionListSmall,
  TipoComandoConversionList } from '../data/general-objects';
import { MatSnackBar } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: CoreModule
})
export class GeneralInfoService {

  constructor(
    private snackBar: MatSnackBar,
    private translate: TranslateService
  ) { }

  // //////////////////////////////////////////////////////////
  // Transpaso de datos para el titulo principal
  // //////////////////////////////////////////////////////////
  private _tituloPrincipalSource = new Subject<string>();
  public tituloPrincipal$ = this._tituloPrincipalSource.asObservable();
  public activeLang = 'es';

  SetTituloPrincipal(titulo: string) {
    this._tituloPrincipalSource.next(this.GetTranslation(titulo));
    // sessionStorage.setItem('currentUser', JSON.stringify(titulo));
  }

  public GetTranslation(key: string):string {
    return  this.translate.instant(key);
  }

  public setDefaultLang(lang: string){
    this.translate.setDefaultLang(lang);
  }

  public useLanguage(lang: string){
    this.activeLang = lang;
    sessionStorage.setItem('currentLanguage',lang);
    this.translate.use(lang);
  }

  public getActiveLanguage(){
    let lang =sessionStorage.getItem('currentLanguage');
    if(lang!=null){
      return lang
    }
    return this.activeLang;
  }
  // //////////////////////////////////////////////////////////
  // SOPORTE GENERAL
  // //////////////////////////////////////////////////////////

  // Coordenadas de la ciudad de san nicolas
  public GetCoordenadasSanNicolas(): number[] {
    return [-60.2202, -33.3486];
  }

  /**
   * Lanza un snack para avisar que ya se mando un mensaje de aplicacion de cambios
   */
   public LanzarSnackAviso(mensaje: string, time: number = 2000) {
    this.snackBar.open(mensaje, 'OK', {
      duration: time,
    });
  }

  /////////////////////////////////////////////////////////////////////////////
  // CONVERSION DE DATOS
  /////////////////////////////////////////////////////////////////////////////

  public ConvertContactorTypeToString(ctype: ContactorType): string {
    const pos = ContactorConversionList.findIndex(p => p.val === ctype);
    if (pos >= 0) {
      return ContactorConversionList[pos].ref;
    } else {
      return '---';
    }
  }

  public ConvertStringToContactorType(ctype: string): ContactorType {
    const pos = ContactorConversionList.findIndex(p => p.ref === ctype);
    if (pos >= 0) {
      return ContactorConversionList[pos].val;
    } else {
      return -1;
    }
  }

  public GetContactorTypeConversionList() {
    return ContactorConversionList;
  }

  public ConvertTipoComandoToString(tcmd: TipoComando, small: boolean): string {
    if (small) {
      const pos = TipoComandoConversionListSmall.findIndex(p => p.val === tcmd);
      if (pos >= 0) {
        return this.GetTranslation("tipoComandoConversionList."+TipoComandoConversionListSmall[pos].ref);
      } else {
        return '---';
      }
    } else {
      const pos = TipoComandoConversionList.findIndex(p => p.val === tcmd);
      if (pos >= 0) {
        return this.GetTranslation("tipoComandoConversionList."+TipoComandoConversionList[pos].ref);
      } else {
        return '---';
      }
    }
  }

  public ConvertStringToTipoComando(tcmd: string, small: boolean): TipoComando {
    if (small) {
      const pos = TipoComandoConversionListSmall.findIndex(p => this.GetTranslation("tipoComandoConversionList."+ p.ref) === tcmd);
      if (pos >= 0) {
        return TipoComandoConversionListSmall[pos].val;
      } else {
        return -1;
      }
    } else {
      const pos = TipoComandoConversionList.findIndex(p => this.GetTranslation("tipoComandoConversionList."+ p.ref) === tcmd);
      if (pos >= 0) {
        return TipoComandoConversionList[pos].val;
      } else {
        return -1;
      }
    }
  }

  public GetTipoComandoConversionList() {
    return TipoComandoConversionList;
  }

  public GetTipoComandoConversionListSmall() {
    return TipoComandoConversionListSmall;
  }

}
