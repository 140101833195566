import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { GeneralInfoService } from 'src/app/services/general-info.service';

@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.css']
})
export class SearchBoxComponent implements OnInit {

  @Input() placeHolderValue: string;
  @Output() buscar = new EventEmitter<string>();


  constructor(
    public general: GeneralInfoService
  ) { }

  ngOnInit() {
  }

  Buscar(busqueda: string) {
    this.buscar.emit(busqueda);
  }

  PlaceHolderValue(): string {
    return this.general.GetTranslation(this.placeHolderValue);
  }

}
