import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AuthenticationServiceService } from '../services/authentication-service.service';
import { GeneralInfoService } from '../services/general-info.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login-screen',
  templateUrl: './login-screen.component.html',
  styleUrls: ['./login-screen.component.css']
})
export class LoginScreenComponent implements OnInit {

  @Output() EventLoggedIn = new EventEmitter();

  constructor(
    private router: Router,
    private authService: AuthenticationServiceService,
    private general: GeneralInfoService
  ) { }

  username: string;
  password: string;

  ngOnInit() {
  }

  login(): void {
    this.authService.login(this.username, this.password).subscribe(
      data => this.loginOk(),
      error => this.loginError());
  }

  loginError() {
    this.general.LanzarSnackAviso(this.general.GetTranslation("avisos.credencialesInvalidas"), 10000);
    this.username = '';
    this.password = '';
  }

  loginOk() {
    // this.EventLoggedIn.emit();
    this.router.navigate(['/']);
  }

  onKeyUp(event) {
    if (event.key === 'Enter') {
      this.login();
    }
  }
}
