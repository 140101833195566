import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GeneralInfoService } from '../services/general-info.service';

@Component({
  selector: 'app-translation',
  templateUrl: './translation.component.html',
  styleUrls: ['./translation.component.css']
})
export class TranslationComponent implements OnInit {

  public activeLang=this.general.activeLang;
  constructor(
    private general: GeneralInfoService
  ) {
    this.general.setDefaultLang('es');
  }
  ngOnInit() {
  }
  public cambiarLenguaje(lang) {
    this.general.useLanguage(lang);
    this.activeLang=this.general.activeLang;

}
}
