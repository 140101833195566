import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MenuComponent } from './menu/menu.component';

import { CoreModule } from './services/core.module';
import { ShareModule } from './share/share.module';
import { LoginScreenComponent } from './login-screen/login-screen.component';

import { TranslateLoader, TranslateModule, MissingTranslationHandler } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslationComponent } from './translation/translation.component';

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    LoginScreenComponent,
    TranslationComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ShareModule,
    CoreModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => {
          return new TranslateHttpLoader(http);
        },
        deps: [ HttpClient ]
      }
    }),
    RouterModule.forRoot([
      {path: 'abastecimiento', loadChildren: './abastecimiento/abastecimiento.module#AbastecimientoModule'},
      {path: 'mapas', loadChildren: './mapas/mapas.module#MapasModule'},      
      {path: 'login', loadChildren: './login/login.module#LoginModule'},
      {path: 'config-modulo-mqtt', loadChildren: './config-modulo-mqtt/config-modulo-mqtt.module#ConfigModuloMqttModule'},
      {path: '', loadChildren: './abastecimiento/abastecimiento.module#AbastecimientoModule'},
      {path: '**', loadChildren: './abastecimiento/abastecimiento.module#AbastecimientoModule'}

    ])
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
